import Service from '../Service'

export default class extends Service {
  constructor (id) {
    const url = ['system', 'groups']
    if (id) {
      url.push(id)
    }
    super(url)
  }
}
