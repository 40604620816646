<template>
  <gf-content title="Manage Groups" subtitle="Add groups and manage their permissions">
    <template #toolbar>
      <el-input style="width: 250px" class="mr-4" v-model="search"></el-input>
      <gf-button type="primary" @click="addGroup">
        <template slot="icon">
          <span class="svg-icon svg-icon-white svg-icon-sm">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
              </g>
            </svg>
          </span>
        </template>
        Add
      </gf-button>
    </template>
    <gf-table :data="groups" v-loading="loading" @row-click="readGroup">
      <el-table-column label="Group Name" prop="name" min-width="200">
      </el-table-column>
      <el-table-column label="Member's Count" min-width="170" align="center">
        <template #default="slot">
          {{ slot.row.count }}
        </template>
      </el-table-column>
      <el-table-column label="Created At" min-width="150">
        <template #default="slot">
          {{ $DateFormat(slot.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="Last Updated" min-width="150">
        <template #default="slot">
          {{ $DateFormat(slot.row.updated_at) }}
        </template>
      </el-table-column>
    </gf-table>
    <template #footer>
      <gf-pagination :pagination="pagination" @change="getGroups"></gf-pagination>
    </template>
  </gf-content>
</template>

<script>
// Services
import GroupService from '@/services/v1/Group'
import { debounce } from 'lodash'

export default {
  data () {
    return {
      groups: [],
      search: '',
      filter: {},

      loading: false,

      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      this.getGroups()
    }, 400)
  },
  methods: {
    // navigation
    addGroup () {
      this.$router.push({ name: 'groups-add' })
    },
    readGroup (row) {
      this.$router.push({ name: 'groups-view', params: { id: row.id } })
    },

    async getGroups () {
      try {
        this.loading = true
        const gpService = new GroupService()
        const response = await gpService.list(this.pagination.page, null, this.filter, null)
        this.groups = response.data.rows

        if (response.data.count === 0 && response.data.rows.length !== 0) {
        } else this.pagination.total = response.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.$emit('update:active', 2)

    await this.getGroups()
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Settings' },
      { title: 'Company Details' },
      { title: 'Groups' }
    ])
  }
}
</script>
